import React, { useEffect } from 'react'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DdrBanner from './DdrBanner'
import DdrForm from './DdrForm'
import DdrFeatures from './DdrFeatures'
import DdrFaq from './DdrFaq'
import { useParams } from 'react-router-dom'
import HomeGoogleRev from '../Home/HomeGoogleRev'
import DdrOfferBanner from './DdrOfferBanner'

const DefensiveDrivingRemote = () => {
  const { id } = useParams()
  useEffect(() => {
    if (id == 1) {
      window.scrollTo({ top: 0, behavior: 'auto' });
    } else {
      window.scrollTo(0, 470);
    }

  }, [])
  return (
    <>

      <Header />
      <DdrBanner />
      <DdrForm />
      <DdrFeatures />
      <HomeGoogleRev />
      <DdrFaq />
      <Footer />

    </>
  )
}

export default DefensiveDrivingRemote
