
import MainRoutes from "./Routes/MainRoutes";


import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { SnackbarProvider } from "notistack";
// ..
AOS.init();


function App() {
  return (
  
    <>
    <SnackbarProvider>
      <MainRoutes />
    </SnackbarProvider>
      
    </>
   
  );
}

export default App;
