import React, { useEffect } from 'react'
import Header from '../../components/Header'
import { Typography } from '@mui/material';
import HomeAbout from './HomeAbout';
import HomeBanner from './HomeBanner';
import HomeProgram from './HomeProgram';
import HomeFaq from './HomeFaq';
import HomeWhyChoose from './HomeWhyChoose';
import HomeJourney from './HomeJourney';
import HomeGoogleRev from './HomeGoogleRev';
import HomeBotCarosuel from './HomeBotCarosuel';
import Footer from '../../components/Footer';

const Home = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [])
  return (
    <>

        <Header /> 
        <HomeBanner />
        <HomeAbout />
        <HomeProgram />
        <HomeGoogleRev />
        <HomeFaq />
        <HomeWhyChoose />
        <HomeJourney />
        <HomeBotCarosuel />
        <Footer />
  
    </>
  )
}

export default Home;
