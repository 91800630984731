import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button, CardMedia } from '@mui/material';
import abt1 from '../../assets/images/home/abtlft1.png';
import abt2 from '../../assets/images/home/abtlft2.png';
import abtlftcer from '../../assets/images/home/abtlftcer.png';
import abtlfttck from '../../assets/images/home/abtlfttck.png';
import abtbut1 from '../../assets/images/home/abtbut1.png';
import abtbut2 from '../../assets/images/home/abtbut2.png';



const styles = {

  aboutRight: {
    '@media (max-width: 900px)': {
      marginTop:'60px',
    },
  },

  About2Image: {
    position: 'absolute',
    width: '310px',
    bottom: '-60px',
    right: '100px',
    border: '5px solid #0063d3',
    borderRadius: '12px',

    '@media (max-width: 900px)': {
      display:'none',
    },
  },

  About1Image: {
    border: '5px solid #0063d3', borderRadius: "16px", width: '500px', maxWidth: '100%'
  },

  aboutLeft: {
    position:'relative',
    '@media (max-width: 900px)': {
        width:'90%',
        marginLeft:'auto',
    },
  },

  aboutButtons: {
    marginTop: '30px', display: 'flex', gap:'20px',
    '@media (max-width: 900px)': {
        flexDirection:'column',
    },
  }

  

}


const HomeAbout = () => {
  const [count, setCount] = useState(0);
  const limit = 10; // Set your desired count limit

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < limit) {
          return prevCount + 1;
        } else {
          clearInterval(timer); // Stop the interval when the limit is reached
          return prevCount;
        }
      });
    }, 300); // Adjust the interval as needed

    return () => {
      clearInterval(timer);
    };
  }, [limit]);

  return (
    <>
      <Container maxWidth='lg'>
        <Box sx={{ flexGrow:'1', margin:'120px 0px','@media (max-width: 900px)': {margin:'120px 0px 60px'}  }}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Grid className='abt_lft' sx={styles.aboutLeft} >
                <Grid className='abt_cer' sx={{ position: 'absolute', right: '50px', top: '-60px','@media (max-width: 600px)': {right: '20px'}  }}>
                  <img src={abtlftcer} alt="" className='certifi' style={{ filter: 'drop-shadow(0px 0px 0px black)' }} />
                  <img src={abtlfttck} alt="" className='' style={{ position: 'absolute', right: '37px', top: '37px' }} />
                </Grid>

                <img src={abt1} alt="" className='' style={styles.About1Image} />

                <div className='abt_lft2'>
                  <div className='' style={{ textAlign: 'center', background:'#ffffff38', borderRadius:'8px', padding:'18px 30px', }}>
                    <Typography variant='h6' sx={{color: '#fff !important', fontSize:'16px !important', fontWeight:'500 !important'}}>Expertise</Typography>
                    <Typography variant='h1' sx={{color: '#fff !important'}} className="counter">{count}+</Typography>
                    <Typography variant='h6' sx={{color: '#fff !important', fontSize:'14px !important', fontWeight:'400 !important'}} >Years</Typography>
                  </div>
                </div>
               
                <CardMedia component="img" alt="Image" src={abt2} sx={styles.About2Image}/>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <Grid className='abt_rht' sx={styles.aboutRight}>
                <Typography>About us</Typography>
                <Typography variant='h4' sx={{ pt: 2 }}>We prioritize service above everything else.</Typography>
                <Typography sx={{ pt: 2 }}>Our courteous and dedicated staff are committed to providing top-notch instruction and support to drivers of all levels, ensuring that each student receives personalized attention and guidance throughout your learning journey.</Typography>

                <Grid className='abt_but' sx={styles.aboutButtons}>

                  <Button variant="outlined"
                    sx={{ background: '#EAF4F0', color: 'var(--green)', border: 'none', fontSize: '14px', fontWeight: '500', "&:hover": { background: 'var(--green)', color: 'white', border: 'none' } }}>
                    <img src={abtbut1} style={{ width: '40px', }} />&nbsp; Operational Excellence
                  </Button>

                  <Button variant="outlined"
                    sx={{ background: '#EAF4F0', color: 'var(--green)', border: 'none', fontSize: '14px', fontWeight: '500', "&:hover": { background: 'var(--green)', color: 'white', border: 'none' } }}>
                    <img src={abtbut2} style={{ width: '40px', }} />&nbsp; customer satisfaction
                  </Button>
                </Grid>

                <div className='abt_but' style={{ marginTop: '30px', }}>
                  <a href="#progam_offers" className="btns btn_1 hvr-bounce-to-right">KNOW MORE</a>
                </div>

              </Grid>

            </Grid>
          </Grid>
        </Box>

      </Container>

    </>
  )
};

export default HomeAbout;
