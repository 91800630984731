import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Register from '../Register/Register'
import { useState } from 'react'

import drrlEsyStpBg from "../../assets/images/DefensiveDrivingLocation/drrlEsyStpBg.png"
import easyStepsRoad from "../../assets/images/DefensiveDrivingLocation/EasyStepsRoad.png"



const styles = {
    easyStepRoadimg: {
        backgroundSize:'100% 100%',
        objectFit:'fill',
        width:'140px',
        height:'150px',
    },
    EsyStpBox: {
        display:'grid !important',
        gridTemplateColumns:'40% 20% 40%',
        alignItems:'center',
    }
};


const DrrlEasySteps = () => {

    const [registerStatus, setRegisterStatus] = useState(false)

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        beforeChange: function(currentSlide, nextSlide) {
          console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function(currentSlide) {
          console.log("after change", currentSlide);
        },
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                vertical: true,
                verticalSwiping: true,
              },
            },
        ],
      };


  return (
    <>

        <Box className="EasyStepBg" sx={{ pt:"50px", pb:'80px', background: `url(${drrlEsyStpBg}) no-repeat top center`,}}>
            <Container>

                <Grid>
                    <Typography variant="h4" sx={{ pb:'80px',  textAlign:'center', }}> Easy Steps to complete the DUI / Risk Reduction </Typography>
                </Grid>
                
                <Grid>
                <div className="slider-container DrrlSlider">
                    <Slider {...settings}>

                        <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                            <Grid className='esystptxt esystptxtlft esystptxtlft1'>
                                <Grid>
                                    <Typography variant='h2' sx={{mb:'16px',}}>Gear Up</Typography>
                                    <Typography component='f14'>Enroll for Weekday or Weekend 20-hour DUI Class</Typography>
                                </Grid>
                            </Grid>
                            <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                                <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                            </Grid>
                            <Grid className='esystptxt esystptxtrht'>
                            </Grid>
                        </Box>

                        <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                            <Grid className='esystptxt esystptxtlft'>
                            </Grid>
                            <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                                <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                            </Grid>
                            <Grid className='esystptxt esystptxtrht esystptxtrht2'>
                                <Grid>
                                    <Typography variant='h2' sx={{mb:'16px',}}>GARRP Invitation and Registration</Typography>
                                    <Typography component='f14'>Needs Assessment completion </Typography><br/>
                                    <Typography component="a" href='Register'>How to Register on GARRP</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                            <Grid className='esystptxt esystptxtlft esystptxtlft3'>
                                <Grid>
                                    <Typography variant='h2' sx={{mb:'16px',}}>20-HR DUI/ Risk Reduction Course</Typography>
                                    <Typography component='f14'>Receive class link and Complete 20-DUI class consecutively</Typography>
                                </Grid>
                            </Grid>
                            <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                                <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                            </Grid>
                            <Grid className='esystptxt esystptxtrht'>
                            </Grid>
                        </Box>

                        <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                            <Grid className='esystptxt esystptxtlft'>
                            </Grid>
                            <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                                <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                            </Grid>
                            <Grid className='esystptxt esystptxtrht esystptxtrht4'>
                                <Grid>
                                    <Typography variant='h2' sx={{mb:'16px',}}>Assessment</Typography>
                                    <Typography component='f14'>Student Performance evaluation</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                            <Grid className='esystptxt esystptxtlft esystptxtlft5'>
                                <Grid>
                                    <Typography variant='h2' sx={{mb:'16px',}}>Certification</Typography>
                                    <Typography component='f14'>Certificates will always be emailed next day and from <b>Georgia Department of Driver Services</b></Typography>
                                </Grid>
                            </Grid>
                            <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                                <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                            </Grid>
                            <Grid className='esystptxt esystptxtrht'>
                            </Grid>
                        </Box>
                    </Slider>
                </div>

                </Grid>

            </Container>
        </Box>
      
    </>
  )
}

export default DrrlEasySteps
