import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import DljlBanner from './DljlBanner'
import DljlFeatures from './DljlFeatures'
import DljlFaq from './DljlFaq'
import DljlPrerequisites from './DljlPrerequisites'
import DljlOfferGreenBanner from './DljlOfferGreenBanner'

const DrivingLessonJoshuaLaw = () => {

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      
      <Header />
      <DljlBanner /> 
      <DljlPrerequisites />
      <DljlOfferGreenBanner />
      <DljlFeatures /> 
      <DljlFaq/> 
      <Footer />  

    </>
  )
}

export default DrivingLessonJoshuaLaw
