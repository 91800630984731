import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DljlFaqImg from "../../assets/images/driving-lesson/DljlFaqImg.png";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const styles ={

  faqRight: {

    '@media (max-width: 900px)': {
        margin:'auto',

    },
  },
  faqRightImage: {
    width:'80%',marginRight:'auto', marginY:'30px',
    '@media (max-width: 900px)': {
      width:'100%'
    }
  }
}

const drivingLessonsFaq = [
{
    question:"What is Joshua's Law, and how does it impact driver's education requirements?",
    answer: "Joshua's Law mandates that 16 and 17-year-old students who require a driver's license must complete a 30-hour driver education course and receive at least 6 hours of professional driving instruction from a certified school.",
},
{
  question:"What are the benefits of enrolling in a DDS-approved driver education course with 1 Act Driving Schools?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"What topics are covered in the DDS-approved driver's education courses offered by 1 Act Driving Schools?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"How can parents monitor students under 18 during the course?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},

]


const DloFaq = () => {
  return (
    <>

    <Container>

      <Box sx={{ py:"50px",}}>

        <Grid sx={{border:'1px solid var(--primary)', padding:'20px 40px',borderRadius:'10px', background:'var(--lightblue)'}}>
          <Typography p="10px 0px 10px" variant='h6'>Don't worry parents--this online driver's education course isn't the ONLY class option you have when you choose 1 ACT Driving Schools! Visit our <a href="driving-lesson-joshuas-law">driver's education </a> page for more information on the class and registration with 1 ACT Driving Schools. We look forward to working with you!</Typography>

          {/* <Typography variant='h6' sx={{display:'flex', alignItems:'baseline', p:'10px 0px 0px'}}><FiberManualRecordIcon sx={{ fontSize: '10px', color:'var(--primary)' }}/>&nbsp; Discount on Insurance Premium</Typography>
          <Typography component='f14'> Completing a certified driver's education program can reduce your car insurance premium by at least 10% as stated by OCGA 33-9-42.</Typography>
          <Typography variant='h6' sx={{display:'flex', alignItems:'baseline', p:'10px 0px 0px'}}><FiberManualRecordIcon sx={{ fontSize: '10px', color:'var(--primary)' }}/>&nbsp; Tax Credit</Typography>
          <Typography component='f14'>According to OC.G.A. 48-7-29.5, enrolling your child in a driver's education course offered by a licensed private company like 1 ACT qualifies you for a tax credit of the amount paid for the course or $150 on your Georgia state income taxes.</Typography> */} 
        
        </Grid>

      </Box>


      <Box sx={{ py:"50px",  position: "relative",}}>
        <div className="DrivingLessonsFaq_ani"></div>
          <Grid container spacing={2} sx={{display:'flex', alignItems:'center',}}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <Grid data-aos="fade-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft " component="img" alt="faq Image" src={DljlFaqImg} sx={styles.faqRightImage}/>
              </Grid>

            </Grid>
            
            <Grid item md={6}>
              <div>

              { drivingLessonsFaq.map((drivingLessonsFaq, index ) => (

                <Accordion key={index} defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6">
                      {drivingLessonsFaq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{fontSize:'15px !important',}} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg,"<br/>","'",".","") }}></Typography>

                  </AccordionDetails>
                </Accordion>
              ))}

              </div>
            </Grid>

          </Grid>
        
      </Box>

      </Container>
    </>
  )
}

export default DloFaq;
