import React, { useState } from 'react';
import { Stack, Button, Grid, TextField, InputAdornment, InputLabel, Typography } from '@mui/material';
import { CheckOutlined } from '@ant-design/icons';
import cvv from '../../assets/cvv.png';
import master from '../../assets/master-card.png';
import MainCard from '../../components/MainCard';

const PaymentPage = ({ onClickSubmit, setActiveStep, coursedetails, paymentDetails, setPaymentDetails }) => {


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Validation logic
        if (name === 'cvvNumber') {
            if (value.length > 4) {
                return;
            }
        }

        if (name === 'expiryMonth') {
            if (value.length > 2) {
                return;
            }
        }

        if (name === 'expiryYear') {
            if (value.length > 2) {
                return;
            }
        }

        // if (name === 'cardNumber') {
        //     if (!/^\d*$/.test(value)) {
        //         console.error('Card number should contain only digits');
        //         return;
        //     }
        // }

        setPaymentDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const roadTest = coursedetails?.some(course => course.type === 5);
    const handle = () => {
        if (roadTest) {
            setActiveStep(1);
        } else {
            setActiveStep(0);
        }
    };

    const getImage = () => {
        return <img src={master} alt="card" />;
    };



    console.log('paymentDetails', paymentDetails)

    return (
        <div>
            <MainCard sx={{ mt: 2 }}>
                <Grid container spacing={6} alignItems={'center'}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Stack>
                                    <InputLabel>Card Number :</InputLabel>
                                    <Typography color="caption" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '12px !important', color: 'gray !important', fontWeight: '100 !important' }}>
                                        Enter the 16 digit card number on the card
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField

                                    size='small'
                                    fullWidth
                                    type="number"
                                    name="cardNumber"
                                    value={paymentDetails.cardNumber}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{getImage()}</InputAdornment>,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CheckOutlined />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Stack>
                                    <InputLabel>Expiry Date :</InputLabel>
                                    <Typography color="caption" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '12px !important', color: 'gray !important', fontWeight: '100 !important' }}>
                                        Enter the expiration on the card
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <TextField
                                                type="number"
                                                fullWidth
                                                placeholder="01"
                                                size='small'
                                                name="expiryMonth"
                                                value={paymentDetails.expiryMonth}
                                                onChange={handleInputChange}
                                            />
                                            <InputLabel>/</InputLabel>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            placeholder="25"
                                            size='small'
                                            name="expiryYear"
                                            value={paymentDetails.expiryYear}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Stack>
                                    <InputLabel>CVV Number :</InputLabel>
                                    <Typography color="caption" sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '12px !important', color: 'gray !important', fontWeight: '100 !important' }}>
                                        Enter the 3 or 4 digit number on the card
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    type="number"
                                    size='small'
                                    fullWidth
                                    name="cvvNumber"
                                    value={paymentDetails.cvvNumber}
                                    onChange={handleInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={cvv} alt="CVV" />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>

            <Stack justifyContent="space-between" direction="row" sx={{ mt: 3 }}>
                <Button variant="contained" onClick={handle}>Back</Button>
                <Button variant="contained" color="primary" onClick={onClickSubmit}>
                    Submit
                </Button>
            </Stack>
        </div>
    );
}

export default PaymentPage;
