
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
// import MainCard from 'components/MainCard';

// third-party


// assets
import completed from '../../assets/completed.png';



// ==============================|| CHECKOUT CART - ORDER COMPLETE ||============================== //

const SuccessPage = () => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Grid item>
                {/* <MainCard border={false}> */}
                <Stack spacing={2} alignItems="center">
                    <Box sx={{ position: 'relative', width: { xs: 320, sm: 500 } }}>
                        <img src={completed} alt="Order Complete" style={{ width: 'inherit' }} />
                    </Box>
                    <Typography variant={matchDownMD ? 'h3' : 'h1'} align="center">
                        Thank you for your purchase!
                    </Typography>
                    <Box sx={{ px: 2.5 }}>
                        <Typography align="center" color="textSecondary">
                            Check your Email for username and password
                        </Typography>
                        {/* <Typography align="center" color="textSecondary">
                Your order ID:{' '}
                <Typography variant="subtitle1" component="span" color="primary">
                  {chance.guid()}
                </Typography>
              </Typography> */}
                    </Box>
                    {/* <Typography variant="h5" sx={{ py: { xs: 1, sm: 3 } }}>
                        (219) 404-5468
                    </Typography> */}
                    <Stack direction="row" justifyContent="center" spacing={3}>
                        <Button
                            component={Link}
                            to="/driving-lesson/1"
                            variant="outlined"
                            color="primary"
                            size={matchDownMD ? 'small' : 'medium'}
                        >
                            Continue Purchase
                        </Button>
                        <Button
                            variant='contained'
                            color='success'
                            sx={{color:'white !important'}}
                            href='https://user.projectsideaboxdigital.io.in'
                            target='_blank'
                        >
                            Go to Dashboard
                        </Button>
                    </Stack>
                </Stack>
                {/* </MainCard> */}
            </Grid>
        </Grid>
    );
};



export default SuccessPage;
