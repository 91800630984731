import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import roadBg from '../../assets/images/driving-lesson/road_bg.png'
import conex from '../../assets/images/driving-lesson/conex.png'

const styles ={
    roadMap: {
        marginTop:'30px',background:`url(${roadBg})`, height:'800px', position:'relative', padding:'20px',
        '@media (max-width: 600px)': {
            height:'900px',
            padding:'20px 10px',
        }
    }
}


const BehindTheWheel = () => {
  return (
    <>

        <Box sx={{ flexGrow: 1, background:'#fff',padding:'50px 0px 50px', }} >
            <Container maxWidth="lg" >
                <Grid>
                    <Typography variant='h4'>From Learner to Licensed</Typography>
                    <Typography mt={1}>Your Complete Guide to Becoming a New Driver</Typography>
                </Grid>

                <Grid className='road_map' sx={styles.roadMap}>

                    <Grid className='road_con  road_con1' sx={{width:'40%', margin:'60px 0px 0px','@media (max-width: 600px)': {width:'100%',mt:'30px',}}}>
                        <Typography variant='h2'><img src={conex} className='conex' />&nbsp; Sign Up</Typography>
                        <Typography sx={{paddingTop:'10px',}}>Register online for our driving classes.</Typography>
                    </Grid>

                    <Grid className='road_con  road_con2' sx={{width:'40%', margin:'60px auto 0px','@media (max-width: 600px)': {width:'100%',mt:'30px',}}}>
                        <Typography variant='h2'><img src={conex} className='conex' />&nbsp; Schedule Classes</Typography>
                        <Typography sx={{paddingTop:'10px',}}>Choose flexible class times that fit your schedule or pick an alternative that works best for you.</Typography>
                    </Grid>

                    <Grid className='road_con  road_con3' sx={{width:'40%', marginLeft:'auto', marginTop:'60px','@media (max-width: 600px)': {width:'100%',mt:'30px',}}}>
                        <Typography variant='h2'><img src={conex} className='conex' />&nbsp; Set Your Route</Typography>
                        <Typography sx={{paddingTop:'10px',}}>Decide on the most convenient pick-up and drop-off locations for your lessons.</Typography>
                    </Grid>

                    <Grid className='road_con  road_con4' sx={{width:'40%', margin:'60px auto 0px','@media (max-width: 600px)': {width:'100%',mt:'30px',} }}>
                        <Typography variant='h2'><img src={conex} className='conex' />&nbsp; Choose Your Instructor</Typography>
                        <Typography sx={{paddingTop:'10px',}}>Select an instructor based on your preferred location and schedule.</Typography>
                    </Grid>

                    <Grid sx={{display:'flex', justifyContent:'space-between','@media (max-width: 600px)': {flexDirection:'column',}}}>
                        <Grid className='road_con  road_con5' sx={{width:'40%', margin:'60px 0px 0px','@media (max-width: 600px)': {width:'100%',mt:'30px',} }}>
                            <Typography variant='h2'><img src={conex} className='conex' />&nbsp; Complete Paperwork</Typography>
                            <Typography sx={{paddingTop:'10px',}}>Let us manage all the required paperwork, so you can concentrate on learning.</Typography>
                        </Grid>

                        <Grid className='road_con  road_con6' sx={{width:'40%', margin:'40px 0px 0px','@media (max-width: 600px)': {width:'100%',mt:'30px',} }}>
                            <Typography variant='h2'><img src={conex} className='conex' />&nbsp; Hit the Road!</Typography>
                            <Typography sx={{paddingTop:'10px',}}>Begin your journey to becoming a confident and skilled driver!</Typography>
                        </Grid>
                    </Grid>

                </Grid>

            </Container>
        </Box>
      
    </>
  )
}

export default BehindTheWheel
