import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import tick from "../../assets/images/driving-lesson/tick.png"

import RlthousantBg from "../../assets/images/roadTesting/RlthousantBg.png";

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useEffect, useState } from "react";
import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import Register from '../Register/Register';
import { useNavigate } from 'react-router-dom';

import GreenBanner from '../BaseUrl/GreenBanner';


const styles = {
    greenBox: {
        background: `url(${RlthousantBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '40px 80px', marginTop: '40px',
        '@media (max-width: 900px)': {
            padding: '30px',
            marginTop: '20px',
        },
    }
}

const programsData = [
    {
        prmTop1: "Starting",
        prmTop2: "$95",
        prmTittle: "Defensive Driving",
        prmDescription:
            "6 hours",
    },
];


const DdrForm = () => {


    const [roadTesting, setRoadTesting] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllCourse`, {
                type: ''
            })
            if (response.data.message === "success") {
                console.log('response', response?.data?.response)

                const course5 = response?.data?.response[2]
                setRoadTesting(course5)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
    }, [])
    console.log('roadTesting', roadTesting)



    const [registerStatus, setRegisterStatus] = useState(false)

    const [finalData, setFinalData] = useState({})

    const fetchcheckPackageisExist = async (packageid) => {
        try {
            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageid
            })
            if (response.data.message === 'success') {
                console.log('response', response?.data?.response[0])
                setFinalData(response?.data?.response)
                setRegisterStatus(true)
            } else {
                setFinalData({})
            }
        } catch (error) {
            console.log(error)
        }
    }



    console.log('finalData', finalData)







    const navigate = useNavigate()


    return (
        <>

            <Box sx={{ py: "50px", }}>
                <Container maxWidth='lg'>

                    <Grid item xs={8}>
                        <Typography variant="h4" sx={{ pt: 2 }}>What you get help from this?</Typography>
                    </Grid>

                    <Grid container spacing={2} mt={2}>

                        <Grid item md={7} >
                            <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '2px 6px 0px 0px' }} /> &nbsp; <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '18px !important', }}>Ticket dismissal</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '2px 6px 0px 0px' }} /> &nbsp; <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '18px !important', }}>Point reduction</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '2px 6px 0px 0px' }} /> &nbsp; <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '18px !important', }}>Fine reduction</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '2px 6px 0px 0px' }} /> &nbsp; <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '18px !important', }}>License reinstatement</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '2px 6px 0px 0px' }} /> &nbsp; <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '18px !important', }}>Other court matters</Typography></Grid>
                                <Grid sx={{ display: 'flex', }}><img src={tick} style={{ width: '20px', height: '20px', margin: '2px 6px 0px 0px' }} /> &nbsp; <Typography variant='h2' sx={{ color: 'var(--primary) !important', fontSize: '18px !important', }}>Insurance premium discount valid for 3 years (minimum of 10%)</Typography></Grid>
                            </Grid>

                            <Grid sx={{ background: '#EAF4FF', padding: '30px', mt: '40px', }}>
                                {/* <Typography variant='h5' color='#000 !important' sx={{ marginBottom: '14px', }} >Attend a Class Conveniently Located Near You </Typography> */}
                                <Typography  sx={{ marginBottom: '14px',}}><b>Certificate of Completion is the same whether you took the class virtually via zoom or in-person at our office.</b></Typography>
                                <Typography component='span1' sx={{ color: 'var(--primary)', fontWeight: '600 !important', }}>Weekly Class Schedule</Typography>

                                <Grid sx={{ margin: '30px 0px 0px', display: 'flex', gap: '20px', '@media (max-width: 900px)': { flexDirection: 'column' } }}>
                                    <Grid sx={{ background: '#fff', p: '20px 30px ', }}>
                                        <Typography variant='h6' sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}>
                                            <AccessTimeFilledIcon sx={{ color: '#5B5B5B', fontSize: '16px', }} />&nbsp;
                                            <span><b>Mon - Sun (1-day session)</b><br />
                                                <b>9:30 am - 4:30 pm </b></span>
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ background: '#fff', p: '20px 30px ', }}>
                                        <Typography variant='h6' sx={{ fontWeight: '400 !important', display: 'flex', alignItems: 'baseline' }}>
                                            <AccessTimeFilledIcon sx={{ color: '#5B5B5B', fontSize: '16px', }} />&nbsp;
                                            <span><b>Wed - Thurs (2-day sessions) </b><br />
                                                <b>06:00 pm - 09:00 pm </b>(Both nights)</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                
                                <br/>

                                <Typography sx={{ marginBottom: '14px',}}><b>Certificates will always be emailed the next day and from the Georgia Department of Driver Services.</b></Typography>


                            </Grid>

                        </Grid>

                        <Grid item md={5}>
                            {roadTesting?.courseDetails?.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line">
                                        </Grid>


                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                Starting
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                sx={{ color: "#fff !important" }}
                                            >
                                                ${program.packageamount}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_img" style={{ padding: "60px 20px 0px" }} >
                                            <CardMedia
                                                className=""
                                                component="img"
                                                height="200px"
                                                width="100%"
                                                alt="program Image"
                                                src={roadTesting.productimage}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                        </Grid>

                                        <Grid className="prgrm_con">

                                            <Grid className="prgrm_txt">
                                                <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            margin: "0px 0px 16px",
                                                        }}
                                                    >
                                                        {program.packagename}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: "12px !important" }}>
                                                        {roadTesting.description}
                                                    </Typography>
                                                </Grid>

                                                <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "10px" }}>
                                                    <a className="btns btn_1 hvr-bounce-to-right">
                                                        GET NOW
                                                    </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>

                        <Register
                            openStatus={registerStatus}
                            setRegisterStatus={setRegisterStatus}
                            finalData={finalData}
                            classmode = {'1'}
                        />

                    </Grid>

                    {/* <Grid sx={styles.greenBox}>
                        <Typography variant='h4' sx={{ fontWeight: '200 !important', color: '#fff !important', mb: '10px', textTransform: 'uppercase', }}>Thousands of New Drivers Trust Us!</Typography>
                        <Typography variant='h4' component='subtittle2' sx={{ color: 'var(--lightyellow) !important', }}>Join the community with our driving lesson combo deals.</Typography>

                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '30px 0px 0px ', width: 'unset' }}>
                            <Grid>
                                <Button variant="outlined" className=''
                                    sx={{
                                        background: "var(--yellow)",
                                        border: "1px solid var(--yellow)",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        padding: '4px 20px',
                                        transition: '1s',
                                        color: '#000',
                                        borderRadius: '0px',

                                        "&:hover": {
                                            background: "#fff",
                                            color: "#fff",
                                            border: "1px solid var(--green)",
                                            boxShadow: "inset 300px 0px 0px var(--green)",
                                            transition: '1s'
                                        },
                                    }}
                                >
                                    With GREAT OFFERS

                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="outlined" className='greenButAni' onClick={() => navigate('/driving-lesson/2')}>
                                    GET NOW
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}

                    

                </Container>

            </Box>

        </>
    )
}

export default DdrForm
