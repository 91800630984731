import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import conex from "../../assets/images/driving-lesson/conex.png"
import DDZoomImg from "../../assets/videos/DD (Zoom).png"


import Register from '../Register/Register'
import { useState } from 'react'
import axios from 'axios'
import { base_Url } from '../BaseUrl/ApiUrl'


const styles = {
  bannerRight: {
    '@media (max-width: 900px)': {
      paddingTop: '30px',
    }
  },
  dlBanner: {
    flexGrow: 1, background: `url(${drivingLesson}) no-repeat top center`, backgroundSize: '100% 100%', padding: '150px 0px 80px',
    '@media (max-width: 900px)': {
      padding: '50px 0px 50px'
    }
  }
}


const DdrBanner = () => {

  const [roadTesting, setRoadTesting] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.message === "success") {
        console.log('response', response?.data?.response)

        const course5 = response?.data?.response[2]
        setRoadTesting(course5)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [])
  console.log('roadTesting', roadTesting)



  const [registerStatus, setRegisterStatus] = useState(false)

  const [finalData, setFinalData] = useState({})

  const fetchcheckPackageisExist = async (packageid) => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: packageid
      })
      if (response.data.message === 'success') {
        console.log('response', response?.data?.response[0])
        setFinalData(response?.data?.response)
        setRegisterStatus(true)
      } else {
        setFinalData({})
      }
    } catch (error) {
      console.log(error)
    }
  }



  console.log('finalData', finalData)


  return (
    <>
      <Box
        sx={styles.dlBanner}
      >
        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item md={7}>
              <Typography
                variant='h5'
                component=""
                sx={{
                  fontWeight: "400 !important",
                  lineHeight: "1.5",
                  color: "#fff !important",
                }}
              >
                Keep Your Driving Record Clean and Penalty-free With
              </Typography>

              <Typography
                variant="h1"
                sx={{ color: "var(--yellow) !important", mt: '20px', }}
              >
                Defensive Driving (Zoom)
              </Typography>

              <Grid sx={{ mt: "30px", display: 'flex', flexDirection: 'column', gap: '10px', }}>

                {/* <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{width: "20px", height: "20px", margin: "6px 6px 0px 0px", }}/>&nbsp;
                    <Typography color="#fff !important" fontWeight="400 !important" >
                        Employ advanced techniques for maneuvering through demanding driving scenarios and mitigating collision risks
                    </Typography>
                </Grid> */}
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} />&nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Master Safe Driving with State-approved Defensive Driving Course
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} />&nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Improve Your Driving Skills, Reduce Points, and Earn Insurance Discounts
                  </Typography>
                </Grid>

                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} />&nbsp;
                  <Typography color="var(--yellow) !important" fontWeight="600 !important" >
                    DDS certified ZOOM classes
                  </Typography>
                </Grid>

              </Grid>

              <Button variant="outlined" className='greenButAni' onClick={() => fetchcheckPackageisExist([roadTesting?.courseDetails[0]?.packageid])}>
                Register Now
              </Button>
              <Register
                openStatus={registerStatus}
                setRegisterStatus={setRegisterStatus}
                finalData={finalData}
                classmode={'1'}
              />

            </Grid>

            <Grid item md={5}>
              <CardMedia className="" component="img" alt="DD Zoom" src={DDZoomImg} sx={styles.bannerRight} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DdrBanner;
