import React, { useEffect, useState } from 'react';
import { Grid, TextField, InputLabel, IconButton, Card, Stack, Button, FormControl, Box, Autocomplete, Typography, Divider, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Toaster, toast } from "sonner";
import { useTheme } from '@mui/material/styles';
import MainCard from '../../components/MainCard';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import Register from './Register';
import CloseIcon from '@mui/icons-material/Close';
import { MuiPhone } from './MuiPhone';
import { ParentPhone } from './ParentPhone';


const success = (data) => {
    toast.success(data, {
        position: 'top-center',
        style: { backgroundColor: 'green', color: 'white' },

        cancel: {
            label: "close",
            onClick: () => console.log('Cancel!'),
        },
    });
}

const failure = (data) => {
    toast.error(data, {
        position: 'top-center',
        style: { border: '1px solid red' },
        duration: 3000, 
        cancel: {
            label: "x",
            // style: {
            //     backgroundColor: '#a8261d', 
            //     color: '#ffffff', 
            //     border: 'none', 
            //     padding: '2px 8px',
            //     borderRadius: '4px', 
            // },
            onClick: () => console.log('Cancel!'),
        },

    });
}

const RegisterForm = ({ handleNext, handleBack, formData, setFormData, setActiveStep, coursedetails, setRegisterStatus, finalData }) => {
    const theme = useTheme();


    console.log('formData.phonenumber', formData.phonenumber)

    const roadTest = coursedetails?.some(course => course.type === 5);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [loginOpen, setLoginOpen] = useState(false)
    const handleEmailChange = async (e) => {
        const { name, value } = e.target;



        try {
            const response = await axios.post(`${base_Url}/alreadyStudentExist`,
                {
                    "email": value
                }
            )
            if (response.data.status === false) {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            } else {
                toast.error(response.data.message);
                setLoginOpen(true)
                // setRegisterStatus(false)
                window.history.replaceState(null, null, window.location.pathname);
            }
        } catch (error) {
            console.log('error', error)

        }


    };

    const handleDateChange = (e) => {
        console.log('e', e.target.value)
        setFormData({
            ...formData,
            ['Dob']: e.target.value,
        });

    };

    console.log('formData in register form', formData)

    const dob = new Date(formData.Dob);
    const isBelow18 = (new Date().getFullYear() - dob.getFullYear()) <= 18;
    console.log("Is below 18:", isBelow18);


    const NextClick = () => {
        // Validation for each field

        // failure('Date of birth is required')


        if (!formData.firstname) {
            failure('First name is required ',);
            return;
        }

        if (!formData.lastname) {
            failure('Last name is required');
            return;
        }

        if (!formData.email) {
            failure('Email address is required');
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            failure('Invalid email address');
            return;
        }

        if (!formData.phonenumber) {
            failure('Please enter your mobile number.');
            return;
        }
        const phoneNumberRegex = /^(?=.*\d.*\d.*\d.*\d).+$/;
        if (!phoneNumberRegex.test(formData.phonenumber)) {
            failure('Please enter your mobile number.');
            return;
        }
        if (!formData.Dob) {
            failure('Please provide your date of birth',);
            return;
        }



        if (!formData.unitNo) {
            failure('Please provide your suite number');
            return;
        }

        if (!formData.billingaddress) {
            failure('Billing address is required');
            return;
        }

        if (!formData.newcity) {
            failure('City is required');
            return;
        }

        if (!formData.newstate) {
            failure('State is required');
            return;
        }

        if (!formData.newcountry) {
            failure('Country is required');
            return;
        }

        if (!formData.zipcode) {
            failure('Zip code is required');
            return;
        }


        if (isBelow18) {
            if (!formData.parentname) {
                failure(`(for students under 18): Parent's name is required`);
                return;
            }

            if (!formData.parentemail) {
                failure(`(for students under 18): Parent's email address is required`);
                return;
            }

            if (!formData.parentphone) {
                failure(`(for students under 18): Parent's mobile number is required`);
                return;
            }

            if (!formData.relation) {
                failure(`(for students under 18): Please specify the relationship to the student`);
                return;
            }

        }

        let isValid = true;

        coursedetails?.forEach(product => {
            if (product?.type !== 5 && product.type !== 1 && !product.addonid) {
                failure(`Please select a slot for ${product.productname}`);
                isValid = false;
            }
        });

        if (isValid) {
            handleNext(roadTest ? 1 : 2)
            // setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };



    const models = [
        {
            id: 1,
            label: 'Father'
        },
        {
            id: 2,
            label: 'Mother'
        },
        {
            id: 3,
            label: 'Guardian'
        }
    ]

    const genderArray = [
        {
            id: 1,
            label: 'Male'
        },
        {
            id: 2,
            label: 'Female'
        },
        {
            id: 3,
            label: 'Not to disclose'
        }
    ]





    const [countryInfo, setCountryInfo] = useState([]);
    const [countryLoading, setCountryLoading] = useState(true)
    const fetchCountryInfo = async () => {
        try {
            const response = await axios.get(`https://countriesnow.space/api/v0.1/countries/iso`);
            const data = response?.data?.data;
            const result = data.map((item) => ({
                id: item.Iso2,
                label: item.name
            }));

            setCountryInfo(result);
            setCountryLoading(false)
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };






    const [stateInfo, setStateInfo] = useState([]);
    const [stateLoading, setStateLoading] = useState(false)
    const fetchState = async (id) => {

        try {
            setStateInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
                "country": id
            }
            );
            const data = response?.data?.data?.states;
            const result = data.map((item) => ({
                id: item.state_code,
                label: item.name
            }));
            setStateLoading(false)
            setStateInfo(result);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };



    const [cityInfo, setCityInfo] = useState([]);
    const [cityLoading, setCityLoading] = useState(false)
    const fetchCity = async (selectedStateId, country) => {

        try {
            setCityInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
                "country": `${country}`,
                "state": `${selectedStateId}`
            });
            const data = response.data.data;
            const result = data?.map((item) => ({
                id: item,
                label: item
            }));
            setCityInfo(result);
            setCityLoading(false)
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        fetchCountryInfo()
        setFormData({
            ...formData,
            ['newstate']: 'Georgia',
        });
    }, []);


    useEffect(() => {


        if (formData?.newcountry) {
            fetchState(formData?.newcountry)
        }

        if (formData?.newcountry && formData?.newstate) {
            fetchCity(formData?.newstate, formData?.newcountry)
        }
    }, [formData?.newcountry]);





    return (
        <div
            style={{ margin: '0px 10px 10px 10px' }}
        >
            <Toaster
                toastOptions={{
                    duration: 1000,
                    style: {
                        cursor: 'pointer',

                    },
                    // Clickable setting ensures that clicking the toast will close it
                    ariaLive: 'assertive',
                    position: 'top-center',

                }}
            />


            <Register
                openStatus={loginOpen}
                setRegisterStatus={setLoginOpen}
                finalData={finalData}
            />


            <MainCard content={false} sx={{
                mt: 2,
            }}>

                <Grid container spacing={2} sx={{ p: 2, }}>
                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleChange}
                                label='First name'
                                placeholder='First name'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleChange}
                                label='Last name'
                                placeholder='Last name'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleEmailChange}
                                label='Email'
                                placeholder='Email'
                            />
                        </Stack>
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="number"
                                name="phonenumber"
                                value={formData.phonenumber}
                                onChange={handleChange}

                                label='Mobile number'
                                placeholder='Mobile number'
                            />
                        </Stack>
                    </Grid> */}



                    <Grid item xs={12} md={6}>
                        <MuiPhone
                            formValues={formData}
                            setFormValues={setFormData}

                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <TextField
                                required
                                size="small"
                                type="date"
                                name="Dob"
                                value={formData.Dob}
                                onChange={handleDateChange}
                                sx={{ mt: 1 }}
                                label='Date of Birth'
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Stack>

                            <Autocomplete
                                id="model"
                                options={genderArray ? genderArray : []}
                                value={genderArray?.find((option) => option?.label == formData?.gender) || null}
                                // getOptionLabel={(option) => option.modelname}
                                onChange={(event, val) => {
                                    if (val) {
                                        console.log('val', val)
                                        const updatedData = { ...formData }
                                        // updatedData['relation'] = val.id
                                        updatedData['gender'] = val.label
                                        setFormData(updatedData)
                                    } else {
                                        const updatedData = { ...formData }
                                        // updatedData['relation'] = val.id
                                        updatedData['gender'] = ''
                                        setFormData(updatedData)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={"Gender"} size='small'
                                        label={"Gender"} required

                                    />
                                )}
                                sx={{ mt: 1 }}
                                autoHighlight={true}
                            />
                        </Stack>
                    </Grid>




                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField

                                size="small"
                                type="text"
                                name="licenseno"
                                value={formData.licenseno}
                                onChange={handleChange}
                                label='License No'
                                placeholder='License No'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField

                                size="small"
                                type="text"
                                name="licensestate"
                                value={formData.licensestate}
                                onChange={handleChange}
                                label='License state'
                                placeholder='License state'
                            />
                        </Stack>
                    </Grid>





                    <Grid item xs={12} md={2}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="unitNo"
                                name="unitNo"
                                value={formData.unitNo}
                                onChange={handleChange}
                                label='Suite No'
                                placeholder='Suite No'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="billingaddress"
                                value={formData.billingaddress}
                                onChange={handleChange}
                                label=' Address'
                                placeholder=' Address'
                            />
                        </Stack>
                    </Grid>






                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="country-input"
                            loading={countryLoading}
                            options={countryInfo || []}
                            value={countryInfo?.find((option) => option?.label === formData?.newcountry) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setStateLoading(true)
                                    // setCityLoading(true)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newcountry: value?.label,
                                        newcity: '',
                                        newstate: '',
                                    }));
                                    fetchState(value?.label);
                                } else {
                                    // setStateLoading(true)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newcountry: '',
                                        newstate: '',
                                        newcity: '',
                                    }));
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" size="small"

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {countryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>


                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="state-input"
                            loading={stateLoading}
                            options={stateInfo || []}
                            value={stateInfo?.find((option) => option?.label === formData?.newstate) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setCityLoading(true)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newstate: value?.label,
                                        newcity: '', // Clear the city when state changes
                                    }));
                                    fetchCity(value?.label, formData?.newcountry);
                                } else {

                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newstate: '',
                                        newcity: '', // Clear the city when state is cleared
                                    }));
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="State" size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {stateLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            id="cityInfo"
                            options={cityInfo ? cityInfo : []}
                            loading={cityLoading}
                            getOptionLabel={(option) => option.label}
                            value={cityInfo?.find((option) => option?.label == formData?.newcity) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setFormData({
                                        ...formData,
                                        ['newcity']: value?.label,
                                    });
                                } else {
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newcity: '',
                                    }));
                                }

                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="City name" label='City' size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {cityLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}

                                />
                            )}
                            autoHighlight={true}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="zipcode"
                                value={formData.zipcode}
                                onChange={handleChange}
                                label='Zip code'
                                placeholder='Zip code'
                            />
                        </Stack>
                    </Grid>


                </Grid>

            </MainCard>



            <MainCard content={false} sx={{
                mt: 2,
            }}
            >
                <Stack direction={'row'} spacing={1} sx={{ p: 1 }}>
                    <Typography >
                        Parent Details
                    </Typography>
                    <Typography sx={{ color: 'gray !important' }}>
                        {!isBelow18 ? "(optional)" : ""}
                    </Typography>
                </Stack>

                <Divider />

                <Grid container spacing={2} sx={{ p: 2, }} >
                    <>


                        <Grid item xs={12} sm={6}>
                            <Stack>
                                {/* <InputLabel>Parent Name</InputLabel> */}
                                <TextField
                                    size="small"
                                    type="text"
                                    name="parentname"
                                    value={formData.parentname}
                                    onChange={handleChange}
                                    label={!isBelow18 ? " Name " : " Name"}
                                    required={!isBelow18 ? false : true}
                                    placeholder=' Name'
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                {/* <InputLabel>Parent Email</InputLabel> */}
                                <TextField
                                    size="small"
                                    type="text"
                                    name="parentemail"
                                    value={formData.parentemail}
                                    onChange={handleChange}
                                    required={!isBelow18 ? false : true}
                                    label={!isBelow18 ? "Email" : " Email"}
                                    placeholder='Email'
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>

                                {/* <TextField
                                    size="small"
                                    type="number"
                                    name="parentphone"
                                    value={formData.parentphone}
                                    onChange={handleChange}
                                    label={!isBelow18 ? "Mobile No " : "Mobile No"}
                                    placeholder='Mobile No'
                                    required={!isBelow18 ? false : true}
                                /> */}

                                <ParentPhone
                                    formValues={formData}
                                    setFormValues={setFormData}

                                />
                            </Stack>
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Stack>
                                {/* <InputLabel>Relation</InputLabel> */}
                                <Autocomplete
                                    id="model"
                                    options={models ? models : []}
                                    value={models?.find((option) => option?.label == formData?.relation) || null}
                                    // getOptionLabel={(option) => option.modelname}
                                    onChange={(event, val) => {
                                        if (val) {
                                            console.log('val', val)
                                            const updatedData = { ...formData }
                                            // updatedData['relation'] = val.id
                                            updatedData['relation'] = val.label
                                            setFormData(updatedData)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={"Relation"} size='small'
                                            label={!isBelow18 ? "Relation " : "Relation"}
                                            required={!isBelow18 ? false : true}

                                        />
                                    )}
                                    // sx={{ mt: 1 }}
                                    autoHighlight={true}
                                />
                            </Stack>
                        </Grid>







                    </>
                </Grid>

            </MainCard>

            <Stack sx={{ m: 2 }} justifyContent="flex-end" direction="row">
                <Button variant="contained" onClick={() => NextClick()}>Next</Button>
            </Stack>
        </div>
    );
};

export default RegisterForm;
