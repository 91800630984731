import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DdlFaqLft from "../../assets/images/DefensiveDrivingLocation/DdlFaq.png";

const styles ={

  faqRight: {

    '@media (max-width: 900px)': {
        margin:'auto',

    },
  },
  faqRightImage: {
    width:'80%',marginRight:'auto', marginY:'30px',
    '@media (max-width: 900px)': {
      width:'100%'
    },
  },
}

const drivingLessonsFaq = [
{
    question:'What is defensive driving?',
    answer: 'Defensive driving is a set of skills and techniques used to help drivers predict risks and prevent accidents on the road',
},
{
  question:'Why should I take a defensive driving course?',
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Will I receive a certificate upon completion?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Can I take the course if I have a busy schedule?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},

]


const DdrFaq = () => {
  return (
    <>
      <Box sx={{ py:"50px",  position: "relative",}}>
        <div className="DrivingLessonsFaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{display:'flex', alignItems:'center',}}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <div data-aos="fade-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                  <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={DdlFaqLft} sx={styles.faqRightImage}/>
              </div>

            </Grid>
            <Grid item md={6}>
              <div>

              { drivingLessonsFaq.map((drivingLessonsFaq, index ) => (

                <Accordion key={index} defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6">
                      {drivingLessonsFaq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{fontSize:'15px !important',}} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg,"<br/>","'",".","") }}></Typography>

                  </AccordionDetails>
                </Accordion>
              ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default DdrFaq;
