import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { CardMedia, Grid, Stack, Box, Button, Popover, Divider, Avatar } from '@mui/material';
import { BrowserRouter as Link, useNavigate } from 'react-router-dom';
import { FaRegUserCircle } from "react-icons/fa";
import logo from "../../assets/images/home/logo.png"
import { AiOutlineDashboard } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import Badge from '@mui/material/Badge';
import { BsCart3 } from "react-icons/bs";
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import { CiWallet } from "react-icons/ci";

import { useMenu, MenuProvider } from '@mui/base/useMenu';
import { useMenuItem } from '@mui/base/useMenuItem';
import { Popper } from '@mui/base/Popper';
import { useDropdown, DropdownContext } from '@mui/base/useDropdown';
import { useMenuButton } from '@mui/base/useMenuButton';
import { useTheme } from '@mui/system';
import clsx from 'clsx';
import _ from 'lodash'
import user from '../../assets/user.jpg';


/*******mobilemenu-offcanvas********/

import { useState } from 'react';
import { Drawer } from '@mui/material';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddtoCart from './AddtoCart';

import ExistingLogin from './ExistingLogin';

/*******mobilemenu-offcanvas********/


const styles = {

  DesktopMenu: {
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },

  MobileMenu: {
    display: 'none',
    '@media (max-width: 900px)': {
      display: 'block',
    },
  },

  headerTop: {
    display: 'flex', justifyContent: 'space-between', padding: '5px 80px',

    '@media (max-width: 1600px)': {
      padding: '5px 10px'
    },
  },

  menuList: {
    display: 'flex', gap: '30px', padding: '0px !important',
    '@media (max-width: 1400px)': {
      gap: '16px',
    },
    '@media (max-width: 1200px)': {
      gap: '4px',
    }
  },

  menuListPadding: {
    width: '100%', display: 'flex', padding: '11px 0px 12px 60px',
    '@media (max-width: 1600px)': {
      padding: '11px 0px 12px 20px',
    },
    '@media (max-width: 1000px)': {
      padding: '5px 0px 5px 20px',
    },
  },

  headerTopText: {
    fontSize: '12px !important', color: '#fff !important',
    '@media (max-width: 1000px)': {
      fontSize: '10px !important',
    },
  },

  mobileCanvas: {
    display: 'none',
    '@media (max-width: 900px)': {
      display: 'block',
    }
  },
  mobileCanvasOuter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },

  accordion: {
    boxShadow: 'unset',
    "&::before": {
      display: 'none',
    },
  },

  AccordionSummary: {
    padding: '0px !important'
  },

  loginButton: {
    background: "var--(blue)",
    color: "#fff",
    border: "1px solid var(--blue)",
    fontSize: "13px",
    fontWeight: "500",
    padding: '5px 24px',
    "&:hover": {
      background: "",
      color: "",
      border: "1px solid var(--blue)",
    },
  }

}



const Menu = React.forwardRef(function Menu(props, ref) {

  const { children, ...other } = props;

  const { open, triggerElement, contextValue, getListboxProps } = useMenu({
    listboxRef: ref,
  });

  return (
    <Popper open={open} anchorEl={triggerElement}>
      <ul className="menu-root" {...other} {...getListboxProps()}>
        <MenuProvider value={contextValue}>{children}</MenuProvider>
      </ul>
    </Popper>
  );
});

const MenuItem = React.forwardRef(function MenuItem(props, ref) {
  const { children, onClick, ...other } = props;

  const { getRootProps, disabled, focusVisible } = useMenuItem({ rootRef: ref });

  const classes = {
    'focus-visible': focusVisible,
    'menu-item': true,
    disabled,
  };

  return (
    <li
      {...other}
      {...getRootProps({ onClick: onClick ?? (() => { }) })}
      className={clsx(classes)}
    >
      {children}
    </li>
  );
});

const MenuButton = React.forwardRef(function MenuButton(props, forwardedRef) {
  const { getRootProps: getButtonProps } = useMenuButton({ rootRef: forwardedRef });

  return (
    <button type="button" {...props} {...getButtonProps()} className="button" />
  );
});



const Header = () => {


  {/********MObileMenu-offcanvas*****/ }
  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };
  {/********MObileMenu-offcanvas*****/ }


  const { contextValue: dropdownContextValue1 } = useDropdown();

  const createHandleMenuClick1 = (menuItem1) => {
    return () => {
      console.log(`Clicked on ${menuItem1}`);
    };
  };

  const { contextValue: dropdownContextValue2 } = useDropdown();
  const createHandleMenuClick2 = (menuItem2) => {
    return () => {
      console.log(`Clicked on ${menuItem2}`);
    };
  };

  const { contextValue: dropdownContextValue3 } = useDropdown();
  const createHandleMenuClick3 = (menuItem3) => {
    return () => {
      console.log(`Clicked on ${menuItem3}`);
    };
  };

  const navigate = useNavigate()




  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log('click')
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openState = Boolean(anchorEl);
  const id = openState ? 'simple-popover' : undefined;



  const handleLogout = () => {
    localStorage.removeItem('Student')
    setAnchorEl(null);

  }



  const studentDetails = JSON.parse(localStorage.getItem('Student'))
  const userid = studentDetails?.customerid

  const [openStatus, setRegisterStatus] = useState(false)


  return (
    <>
      <Box display='flex' sx={styles.DesktopMenu} >

        <CssBaseline />

        <AppBar component="nav" sx={styles.DesktopMenu} >

          <Grid container spacing={2} display='flex' gap='0px'>
            <Grid item md={2}>
              <Typography onClick={() => navigate('/')}>
                <Grid className='logo_box' >
                  <CardMedia
                    className="logo_shape"
                    component="img"
                    alt="logo"
                    src={logo}
                    sx={{ width: '140px', }}
                  />
                </Grid>
              </Typography>
            </Grid>

            <Grid item md={10} sx={{ margin: '0px !important', padding: '16px 0px 0px !important' }}>
              <Grid className='head_top_1'
              // sx={{ background: 'var(--primary)', }}
              >

                <Grid sx={styles.headerTop}>
                  <Grid sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography sx={styles.headerTopText}>Mon-Fri (8am-8pm) / Sat & Sun (8am-6pm)</Typography>
                  </Grid>
                  <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center', }}>
                    <Typography sx={styles.headerTopText}>info@1actdrivingschools.com</Typography>
                    <Typography sx={{ fontSize: '12px !important', color: '#fff !important', fontWeight: '600 !important' }} className='navbar_cal_but'>Call 770-274-4223 | Text 404-590-4570</Typography>
                  </Grid>
                </Grid>

              </Grid>

              <Grid className='head_top_2'>

                <Grid>
                  <Grid className='head_top_2_nav'>

                    <Box sx={styles.menuListPadding}>

                      <nav style={{ display: 'flex', flex: '5.5' }}>
                        <List sx={styles.menuList}>

                          <ListItem disablePadding>
                            <>
                              <DropdownContext.Provider value={dropdownContextValue1}>
                                <MenuButton>DRIVING LESSONS/DRIVERS ED</MenuButton>

                                <Menu id="hooks-menu" >
                                  <MenuItem>
                                    <Typography variant='caption' className="menu-item" onClick={() => navigate('/driving-lesson/1')}>
                                      Driving Lesson
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" onClick={() => navigate('/driving-lesson-joshuas-law')}>
                                      Driver’s Ed - joshua‘s law
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" onClick={() => navigate('/driving-lesson-online')}>
                                      Online - Self Paced Driver’s Ed
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" onClick={() => navigate('/gdec-scholarship')}>
                                      GDEC Scholarship
                                    </Typography>
                                  </MenuItem>

                                </Menu>
                              </DropdownContext.Provider>
                              <Styles />
                            </>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography component='a' onClick={() => navigate('/road-testing')} style={{ cursor: 'pointer' }} >
                              ROAD TESTING
                            </Typography>
                          </ListItem>

                          <ListItem disablePadding>
                            <>
                              <DropdownContext.Provider value={dropdownContextValue2}>
                                <MenuButton>DEFENSIVE DRIVING</MenuButton>
                                <Menu id="defensive_driving">
                                  <MenuItem>

                                    <Typography component='a' variant='caption' className="menu-item" onClick={() => navigate('/defensive-driving-remote/1')}>
                                      Defensive Driving Remote (Zoom)
                                    </Typography>

                                    <Typography component='a' variant='caption' className="menu-item" onClick={() => navigate('/defensive-driving-location')}>
                                      Defensive Driving at Location (In Person)
                                    </Typography>

                                  </MenuItem>
                                </Menu>
                              </DropdownContext.Provider>
                              <Styles />
                            </>
                          </ListItem>

                          <ListItem disablePadding>
                            <>
                              <DropdownContext.Provider value={dropdownContextValue3}>
                                <MenuButton>DUI/RISK REDUCTION</MenuButton>
                                <Menu id="risk_reduction">
                                  <MenuItem>

                                    <Typography variant='caption' className="menu-item" onClick={() => navigate('/dui-risk-reduction-zoom/1')}>
                                      DUI/ Risk Reduction Remote (Zoom)
                                    </Typography>

                                    <Typography variant='caption' className="menu-item" onClick={() => navigate('/dui-risk-reduction-location')}>
                                      DUI/Risk Reduction At Location
                                    </Typography>

                                  </MenuItem>
                                </Menu>
                              </DropdownContext.Provider>
                              <Styles />
                            </>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography sx={{ cursor: 'pointer' }} component='a' onClick={() => navigate('/clinical-evaluation')}>
                              CLINICAL EVALUATION
                            </Typography>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography component='a' sx={{ cursor: 'pointer' }} onClick={() => navigate('/location')}>
                              LOCATIONS
                            </Typography>
                          </ListItem>

                          <ListItem disablePadding>
                            <Typography sx={{ cursor: 'pointer' }} component='a' onClick={() => navigate('/victim-impacts')}>
                              VICTIM IMPACT
                            </Typography>
                          </ListItem>

                        </List>

                      </nav>

                      <nav className='nav2' style={{ display: 'flex', flex: '1', backgroundColor: 'white !important' }}>
                        <List sx={{ display: 'flex', gap: '36px', padding: '0px !important', }}>

                          {

                            (userid) ?



                              < ListItem disablePadding>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }}
                                  onClick={handleClick}
                                >
                                  {/* <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }} src={user} /> */}
                                  <Avatar sx={{ bgcolor: 'orange', width: 30, height: 30 }}>
                                    {studentDetails?.customername?.[0]?.toUpperCase()}
                                  </Avatar>

                                  <Typography sx={{ color: 'white !important' }}>
                                    {studentDetails?.customername}
                                  </Typography>

                                </Stack>

                              </ListItem>

                              : (
                                <ListItem disablePadding>
                                  <Button onClick={() => setRegisterStatus(true)} variant="contained" sx={styles.loginButton} >
                                    LOGIN
                                  </Button>
                                </ListItem>
                              )

                          }

                        </List>
                      </nav>



                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


        </AppBar>
      </Box >


      <ExistingLogin
        openStatus={openStatus}
        setRegisterStatus={setRegisterStatus}
      />


      <Popover
        id={id}
        open={openState}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 98, left: 1300 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >

        {/* <AddtoCart /> */}


        <div style={{ padding: '10px', width: '220px' }}>
          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/myprofile')}>
            <FaRegUserCircle style={{ color: '#0b3863' }} />
            <Typography>My profile</Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />

          {/* <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <CiWallet  style={{ color: '#0b3863' }}/>
            <Typography>Orders</Typography>
          </Stack> */}

          {/* <Divider sx={{ my: 1 }} /> */}

          <a href='https://user.projectsideaboxdigital.io.in' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }}>
              <AiOutlineDashboard style={{ color: '#0b3863' }} />
              <Typography>Go to dashboard</Typography>
            </Stack>
          </a>

          <Divider sx={{ my: 1 }} />

          <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() =>
            handleLogout()

          }
          >
            <IoIosLogOut style={{ color: '#0b3863' }} />
            <Typography>Logout</Typography>
          </Stack>

        </div>





      </Popover >


      {/********mobileHeader*********/}

      < Box sx={styles.mobileCanvas} >

        <Grid sx={styles.mobileCanvasOuter}>
          <Typography component='a' href='/'>
            <CardMedia className="" component="img" alt="logo" src={logo} sx={{ width: '140px', }} />
          </Typography>

          <Button variant="contained" onClick={toggleDrawer(true)} sx={{ height: '46px', }}>
            <DehazeIcon />
          </Button>
        </Grid>

        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}
          ModalProps={{ BackdropProps: { invisible: true, }, }}
        >
          <Grid sx={{ width: '350px', padding: '10px', }}>

            <Grid className='mobileCanvasInHeader' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #1872cb', pb: '20px', }}>
              <Typography component='a' href='/'>
                <CardMedia className="" component="img" alt="logo" src={logo} sx={{ width: '100px', }} />
              </Typography>
              <Button variant="outlined" onClick={toggleDrawer(false)} sx={{ height: '44px', }}><CloseIcon /></Button>
            </Grid>

            {/********mobileContent*********/}

            <Grid className='mobileCanvasInContent' sx={{ mt: '18px' }}>

              <Accordion sx={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={styles.AccordionSummary}>
                  <Typography component="f14" className="menuItemMobBut">
                    DRIVING LESSONS/DRIVERS ED
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="menuItemMob" component="a" href="/driving-lesson/1">
                    Driving Lesson
                  </Typography>

                  <Typography className="menuItemMob" component="a" href="/driving-lesson-joshuas-law">
                    Driver’s Ed - joshua‘s law
                  </Typography>

                  <Typography className="menuItemMob" component="a" href="/driving-lesson-online">
                    Online - Self Paced Driver’s Ed
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Typography className="menuItemMob" component="a" href="/road-testing"> ROAD TESTING </Typography>

              <Accordion sx={styles.accordion} className='accordion'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={styles.AccordionSummary}>
                  <Typography component="f14" className="menuItemMobBut">
                    DEFENSIVE DRIVING
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="menuItemMob" component="a" href="/defensive-driving-location">
                    Defensive Driving at Location (In Person)
                  </Typography>

                  <Typography className="menuItemMob" component="a" href="/defensive-driving-remote/1">
                    Defensive Driving Remote (Zoom)
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={styles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={styles.AccordionSummary}>
                  <Typography component="f14" className="menuItemMobBut">
                    DUI/RISK REDUCTION
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="menuItemMob" component="a" href="/dui-risk-reduction-location">
                    DUI/Risk Reduction At Location
                  </Typography>

                  <Typography className="menuItemMob" component="a" href="/dui-risk-reduction-zoom/1">
                    DUI/ Risk Reduction Remote (Zoom)
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Typography className="menuItemMob" component="a" href="/clinical-evaluation"> CLINICAL EVALUATION  </Typography>

              <Typography className="menuItemMob" component="a" href="/location"> LOCATIONS  </Typography>

              <Typography className="menuItemMob" component="a" href="/victim-impacts"> VICTIM IMPACT  </Typography>

            </Grid>
          </Grid>

        </Drawer>

      </Box >

      {/********mobileHeader*********/}


    </>

  );
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

function useIsDarkMode() {
  const theme = useTheme();
  return theme.palette.mode === 'dark';
}

function Styles() {
  // Replace this with your app logic for determining dark mode
  const isDarkMode = useIsDarkMode();

  const styles = `

    .menu-root {
      font-size: 10px ;
      box-sizing: border-box;
      padding: 10px 4px;
      margin: 10px 0;
      background: #fff;
      border: 1px solid ${grey[200]};
      border-radius: 0.75em;
      color: ${grey[900]};
      overflow: auto;
      outline: 0px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
    }

    .menu-item {
      list-style: none;
      padding: 10px 10px;
      font-size: 12px ;
      font-weight:500;
      cursor:pointer;
      display:block;
      color: #151515 !important;

      &:hover{
        color: #000 !important;
      }
    }

    .button {
      font-weight: 600;
      font-size: 12px;
      padding: 6px 0px;
      transition: all 150ms ease;
      cursor: pointer;
      background: none;
      border: none;
      color: ${isDarkMode ? grey[200] : grey[900]};
      box-shadow: none;
    }
  `;

  // eslint-disable-next-line react/no-danger
  return <style dangerouslySetInnerHTML={{ __html: styles }} />;
}


export default Header;