import React, { useEffect } from 'react';

import './review.css';

const ElfsightWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.setAttribute('data-use-service-core', '');
    script.defer = true;

    script.onerror = () => {
      console.error('Failed to load the Elfsight script.');
    };

    const resizeObserverErrorHandler = (e) => {
      if (e.message == 'ResizeObserver loop completed with undelivered notifications.') {
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener('error', resizeObserverErrorHandler);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.removeEventListener('error', resizeObserverErrorHandler);
    };
  }, []);

  return (
    <div className='reviewG'>
      <div className="elfsight-app-a6b66935-800f-48ae-bd22-6118eeb470ae" data-elfsight-app-lazy></div>
    </div>
  );
};

export default ElfsightWidget;
