import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import locationBannerImg from "../../assets/images/Locations/locationBannerImg.png"

import Register from '../Register/Register'
import { useState } from 'react'

const styles = {
    bannerRight:{
        '@media (max-width: 900px)': {
            paddingTop:'30px',
          }
    }
}


const LBanner = () => {

    const [registerStatus, setRegisterStatus] = useState(false)

  return (
    <>
      
    <Box sx={{ flexGrow: 1, background:`url(${drivingLesson}) no-repeat top center`,backgroundSize:'100% 100%',padding:'150px 0px 80px',   }} >

        <Container>

            <Grid container spacing={2} sx={{alignItems:'center',}}>
                <Grid item md={6}>
                    <Typography variant='h1' sx={{color:'var(--yellow) !important',}}>Our Locations</Typography>
                    <Grid sx={{margin:'40px 0px 0px',}}>
                        <Typography color="#fff !important">1ACT Driving School locations are chosen in such a way that we can serve you better whether it’s <b>in-person or remote.</b> </Typography>
                    </Grid>
                    {/* <Button variant="outlined" className='greenButAni'  onClick={() => setRegisterStatus(true)}>
                        REGISTER
                    </Button> */}
                    <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus}/>

                </Grid>
                
                <Grid item md={6}>
                    <CardMedia className="" component="img" alt="location Banner image" src={locationBannerImg} sx= {styles.bannerRight}/>
                </Grid>
            </Grid>

        </Container>

    </Box>

    </>
  )
}

export default LBanner;
