import {
  Box,
  CardMedia,
  Container,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import prgrm1 from "../../assets/images/home/prgrm1.png";
import prgrm2 from "../../assets/images/home/prgrm2.png";
import prgrm3 from "../../assets/images/home/prgrm3.png";
import prgrm4 from "../../assets/images/home/prgrm4.png";
import prgrm5 from "../../assets/images/home/prgrm5.png";
import axios from "axios";

import Register from "../Register/Register";
import { useNavigate } from "react-router-dom";
import { base_Url } from "../BaseUrl/ApiUrl";


const programsData = [
  {
    prmTop2: "$30",
    prmImage: prgrm1,
    prmTittle: "Behind the Wheel",
    prmDescription:
      "This program covers all essential aspects of safe driving, equipping students with the knowledge and skills needed to become responsible drivers.",
  },
  {
    prmTop2: "$30",
    prmImage: prgrm2,
    prmTittle: "Defensive Driving",
    prmDescription:
      "Learn techniques to anticipate and respond to potential hazards, reducing accident risks and ensuring safer driving practices.",
  },
];

const HomeProgram = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [roadTesting, setRoadTesting] = useState([]);
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: "",
      });
      if (response.data.message === "success") {
        console.log("response", response?.data?.response);

        const demo = response?.data?.response
          // ?.filter((item) => item.type !== 1)
          .flatMap((item) => item.courseDetails);

        console.log("mycourse", demo);
        // const course5 = response?.data?.response[4]
        setRoadTesting(response?.data?.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  console.log("roadTesting", roadTesting);

  const [registerStatus, setRegisterStatus] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{ background: "var(--lightblue)", padding: "50px 0px" }}
        id="progam_offers"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={9} xs={12}>
              <Typography>Drive Confidently with</Typography>
              <Typography variant="h4" sx={{ pt: 2 }}>
                Our Reliable Driver's Education Program Offerings
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <div
            className="slider-container program_slider"
            style={{ marginTop: "50px" }}
          >
            <Slider {...settings}>
              {roadTesting?.map((program, index) => (
                <Grid
                  className="program_box"
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{}}
                >
                  <Grid className="program_box_main" sx={{}}>
                    <Grid className="prgrm_box_line"></Grid>

                    <Grid className="prgrm_top_con">
                      <Typography sx={{ color: "#fff !important" }}>
                        Starting
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{ color: "#fff !important" }}
                      >
                         ${program?.baseprice}
                      </Typography>
                    </Grid>

                    <Grid className="prgrm_con">
                      <Grid className="prgrm_img">
                        <CardMedia
                          className=""
                          component="img"
                          height="100%"
                          width="100%"
                          alt="program Image"
                          src={program?.productimage}
                          sx={{ objectFit: "fill" }}
                        />
                      </Grid>
                      <Grid className="prgrm_txt">
                        <Grid className="" style={{ padding: "20px" }}>
                          <Typography
                            variant="h5"
                            sx={{
                              color: "black !important",
                              // fontSize: "18px !important",
                              // margin: "0px 0px 16px",
                              textAlign: "center",
                            }}
                          >
                            {program?.productname}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px !important", mt: 1 }}
                          >
                            {program?.description}
                          </Typography>
                        </Grid>

                        <Grid
                          className="prgrm_but"
                          style={{ marginTop: "30px" }}
                        >
                          <Button
                            className="btns btn_1 hvr-bounce-to-right"
                            // onClick={() => setRegisterStatus(true)}
                            onClick={() => navigate("/driving-lesson/2")}
                          >
                            BUY NOW
                          </Button>
                          <Register
                            openStatus={registerStatus}
                            setRegisterStatus={setRegisterStatus}
                            data={program.productid}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Slider>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default HomeProgram;
