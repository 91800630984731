import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png'
import RlFeaturesRhtImg from '../../assets/images/roadTesting/RlFeaturesRhtImg.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import pickup from '../../assets/images/driving-lesson/pickup.gif'


const styles = {
    featureBg: {
        flexGrow: 1, background:`url(${ProminentBg}) no-repeat top center`,backgroundSize:'100% 100%',padding:'60px 0px 60px', 
        '@media (max-width: 900px)': {
            padding:'50px 0px 50px 16px', 
        }
    },
    featureGif: {
        width:'70px', height:'70px',
    },
    featureBoxCon: {
        background:'#D7E7FF', display:'flex', gap:'10px', padding:'24px 20px', borderRadius:'6px',
        '&:hover': {background: '#fff',},
        '@media (max-width: 600px)': {
            flexDirection:'column',
        },
        
    },
}

const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Road Test Preparation",
        description: "Get ready for your road test with our expert instructors who stay updated on state practices, offering valuable tips & practice.",

    },
    {
        featureBoxGif: pickup,
        tittle: "Flexible Scheduling",
        description: "Schedule your road test at your convenience, with flexible time slots to fit your busy life",

    },
    {
        featureBoxGif: pickup,
        tittle: "Free Re-take",
        description: "In case you fail on your first attempt, we allow you to retake the test once more for free of cost.",

    },
]

const RtFeatures = () => {
  return (
    <>
    <Box sx={styles.featureBg} >
        <Container >
            <Grid container spacing={2}>

                <Grid item md={6}>
                    {/* <Typography sx={{color:'#fff !important', marginBottom:'10px',}}>Features</Typography> */}
                    <Typography variant='h4' sx={{color:'var(--yellow) !important', }}>From nervous beginner to confident driver</Typography>
                    <Typography variant='h4' sx={{color:'#fff !important', mt:'10px', }}>Get Licensed On Your First Try!</Typography>
                </Grid>

                <Grid item md={6} sx={{display:'flex', justifyContent:'end',}}>
                    <Grid sx={{position:'relative'}}>
                        <img src={RlFeaturesRhtImg} style={{maxWidth:'100%',}}/>
                        <PlayArrowIcon className='playbut'  sx={{position:'absolute',}} />
                    </Grid>
                </Grid>

            </Grid>  

            <Grid container spacing={2} sx={{marginTop:'40px', display:'flex', gap:'14px',}}>

                {featureBox.map((featureBoxItem, index) => (
                <Grid item md={3.9} key={index} sx={{padding:'0px !important',display: 'grid'}}>
                    <Grid sx={styles.featureBoxCon}>
                        
                        <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif}/>
                        <Grid>
                            <Typography variant="h2" sx={{marginBottom:'10px'}}>{featureBoxItem.tittle}</Typography>
                            <Typography component="f15w500">{featureBoxItem.description}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
                ))}

            </Grid>

        </Container>
    </Box>
      
    </>
  )
}

export default RtFeatures;
