import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import MainPage from './MainPage'


const CancellationPolicy = () => {
  return (

    <>
      <Header />
      <MainPage/>  
      <Footer />
    </>
    
  )
}

export default CancellationPolicy
