
import React, { useMemo } from 'react';
import { Slide, DialogTitle, DialogContent, Typography, DialogActions, Checkbox, Button, FormControlLabel, Dialog } from '@mui/material';




import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        animation: `$slideIn 5s`,
        "@keyframes slideIn": {
            "0%": {
                transform: "translateX(-100%)",
            },
            "100%": {
                transform: "translateX(0)",
            },
        },
    },
});



const UpsellingDialog = ({
    open,
    handleClose,
    selectedBTWHour,
    dontShowAgain,
    handleCheckboxChange,
}) => {

    // Memoize dialog content based on `selectedBTWHour` and `dontShowAgain`
    const dialogContent = useMemo(() => {
        if (selectedBTWHour === 2 || selectedBTWHour === 4) {
            return (
                <Typography>
                    Unlock Your Full Potential! Upgrade to 6 hours of BTW classes and grab
                    Drivers Education for just $95 (save $29!). More hours, more confidence,
                    and bigger savings – get started today!
                </Typography>
            );
        } else if (selectedBTWHour === 8 || selectedBTWHour === 10) {
            return (
                <Typography>
                    Maximize your learning! Upgrade to 12 hours of BTW classes and get Defensive Driving absolutely FREE!
                    Don’t miss out on this exclusive offer!
                </Typography>
            );
        } else {
            return null;
        }
    }, [selectedBTWHour]);

    return (
        <AnimatedDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="custom-dialog-title"
            TransitionComponent={Transition}
            transitionDuration={{ enter: 200, exit: 200 }}
            keepMounted
        >
            <DialogTitle id="custom-dialog-title">Special Offer</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>

            <DialogActions>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dontShowAgain}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Do not show again"
                />
                <Button variant="contained" color="error" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </AnimatedDialog>
    );
};

export default UpsellingDialog;
