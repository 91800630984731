import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import drivingLessonsfaq from "../../assets/images/driving-lesson/drivingLessonsfaq.png";

const styles ={

  faqRight: {

    '@media (max-width: 900px)': {
        margin:'auto',

    },
  },
  faqRightImage: {
    width:'80%',marginRight:'auto', marginY:'30px',
    '@media (max-width: 900px)': {
      width:'100%'
    }
  }
}

const drivingLessonsFaq = [
{
question:'What services does 1 Act Driving Schools offer?',
answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:'Are your instructors certified?',
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Do you offer online driver's education courses?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Do you offer online driver's education courses?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"How can I track my progress throughout the driving course?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Are the courses approved by the Georgia Department of Driver Services",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Are there any additional fees that I need to pay?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
]


const DrivingLessonsFaq = () => {
  
  return (
    <>
      <Box sx={{ py:"50px",  position: "relative", background:'#E0EFFF', }}>
        <div className="DrivingLessonsFaq_ani"></div>

        <Container sx={{border:'1px solid var(--primary)', padding:'30px 40px',borderRadius:'10px', background:'var(--lightblue)',marginBottom:'50px'}}>
            <Typography variant="p" sx={{ pt: 2, pb: 2, fontWeight:'400 !important' }}>
              *<b>Cancellations must be made more than 48 hours in advance. Cancellations made between 48 hours or less in advance will incur a late fee of $60. No shows and/or 
              early termination of a lesson on behalf of a client will result in a loss of the remaining lesson time or entire lesson, and the session will not be refundable.</b>
               &nbsp; Please see our refund policy for further details.
            </Typography>
        </Container>

        <Container>
          <Grid container spacing={2} sx={{display:'flex',alignItems:'center',}}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <Grid data-aos="fade-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia
                  className="hmeslidelft"
                  component="img"
                  alt="faq Image"
                  src={drivingLessonsfaq}
                  sx={styles.faqRightImage}
                />
              </Grid>

            </Grid>
            <Grid item md={6}>
              <div>

              { drivingLessonsFaq.map((drivingLessonsFaq, index ) => (

                <Accordion key={index} defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6">
                      {drivingLessonsFaq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{fontSize:'15px !important',}} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg,"<br/>","'",".","") }}></Typography>

                  </AccordionDetails>
                </Accordion>
              ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DrivingLessonsFaq;
