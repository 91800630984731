import React from "react";
import { Box, CardMedia, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CeFaqLft from "../../assets/images/ClinicalEvaluation/CeFaqLft.png";


const styles ={

  faqRight: {

    '@media (max-width: 900px)': {
        margin:'auto',

    },
  },
  faqRightImage: {
    width:'80%',marginRight:'auto', marginY:'30px',
    '@media (max-width: 900px)': {
      width:'100%'
    },
  },
}

const drivingLessonsFaq = [
{
    question:'What are the advantages of taking the DUI/Risk Reduction course online via Zoom?',
    answer: '1DUI/Risk Reduction course online via Zoom offers you the convenience of learning from anywhere with an internet connection. You can attend sessions from the comfort of your home or any other location, saving your time and eliminate the need for travel.',
},
{
  question:'How can I access the online DUI/Risk Reduction course sessions on Zoom?',
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Are there any technical requirements for participating in the online DUI/Risk Reduction course on Zoom?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
  question:"Will I receive course materials and resources for the online DUI/Risk Reduction course on Zoom?",
  answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
    question:"Are the courses approved by the Georgia Department of Driver Services ",
    answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},
{
    question:"Are there any additional fees that I need to pay?",
    answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. uspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
},

]


const DrrlFaq = () => {
  return (
    <>

        <Box sx={{ py:"50px",  position: "relative",}}>
        <div className="DrivingLessonsFaq_ani"></div>
        <Container>
          <Grid container spacing={2} sx={{display:'flex', alignItems:'center',}}>
            <Grid item md={6} sx={styles.faqRight}>
              <Typography variant="h4" sx={{ pt: 2, pb: 2 }}>
                Frequently Asked Question
              </Typography>

              <div data-aos="fade-right"  data-aos-offset="300" data-aos-easing="ease-in-sine">
                <CardMedia className="hmeslidelft" component="img" alt="faq Image" src={CeFaqLft} sx={styles.faqRightImage}/>
              </div>

            </Grid>
            <Grid item md={6}>
              <div>

              { drivingLessonsFaq.map((drivingLessonsFaq, index ) => (

                <Accordion key={index} defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h6">
                      {drivingLessonsFaq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{fontSize:'15px !important',}} dangerouslySetInnerHTML={{ __html: drivingLessonsFaq.answer.replace(/<br\s*\/?>/mg,"<br/>","'",".","") }}></Typography>

                  </AccordionDetails>
                </Accordion>
              ))}

              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
    </>
  )
}

export default DrrlFaq

