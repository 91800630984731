import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import ProminentBg from '../../assets/images/driving-lesson/drvng_lessons_prominent_bg.png';
import CeFeaturesRht from '../../assets/images/ClinicalEvaluation/CeFeaturesRht.png';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import pickup from '../../assets/images/driving-lesson/pickup.gif';


const styles = {
    featureBg: {
        flexGrow: 1, background:`url(${ProminentBg}) no-repeat top center`,backgroundSize:'100% 100%',padding:'60px 0px 60px', 
        '@media (max-width: 900px)': {
            padding:'50px 0px 50px 16px', 
        }
    },
    featureGif: {
        width:'70px', height:'70px',
    },
    featureBoxCon: {
        background:'#D7E7FF', display:'flex', gap:'10px', padding:'24px 14px', borderRadius:'6px',
        '&:hover': {background: '#fff',},
        '@media (max-width: 600px)': {
            flexDirection:'column',
        },
        
    },
    
}

const featureBox = [
    {
        featureBoxGif: pickup,
        tittle: "Comprehensive Curriculum",
        description: "Our course covers DUI/Risk Reduction, including alcohol/drug awareness, defensive driving, and legal responsibilities, for safer driving choices.",

    },
    {
        featureBoxGif: pickup,
        tittle: "Flexible Scheduling Options ",
        description: "We allow you to choose from wide options of convenient class timings and locations tailored to fit your busy schedule.",

    },
    {
        featureBoxGif: pickup,
        tittle: "Convenient Locations",
        description: "With multiple locations conveniently situated across the Atlanta area, we allow easy access to the courses",

    },
]


const DrrzFeatures = () => {
  return (
    <>
      
      <Box sx={styles.featureBg} >
        <Container >

            <Grid container spacing={2}>

                <Grid item md={6}>
                    {/* <Typography sx={{color:'#fff !important', marginBottom:'10px',}}>Features</Typography> */}
                    <Typography variant='h4' sx={{color:'var(--yellow) !important', }}>Get guided by experienced and certified instructors who specialize in</Typography>
                    <Typography variant='h4' sx={{color:'#fff !important', mt:'10px', }}>DUI/Risk Reduction education.</Typography>
                </Grid>

                <Grid item md={6} sx={{display:'flex', justifyContent:'end',}}>
                    <Grid sx={{position:'relative'}}>
                        <img src={CeFeaturesRht} style={{maxWidth:'100%',}}/>
                        <PlayArrowIcon className='playbut'  sx={{position:'absolute',}} />
                    </Grid>
                </Grid>

            </Grid>  

            <Grid container spacing={2} sx={{marginTop:'40px', display:'flex', gap:'14px',}}>

                {featureBox.map((featureBoxItem, index) => (
                <Grid item md={3.9} key={index} sx={{padding:'0px !important',display: 'grid'}}>
                    <Grid sx={styles.featureBoxCon}>
                        
                        <CardMedia component="img" alt="program Image" src={featureBoxItem.featureBoxGif} sx={styles.featureGif}/>
                        <Grid>
                            <Typography variant="h2" sx={{marginBottom:'10px'}}>{featureBoxItem.tittle}</Typography>
                            <Typography component="f15w500">{featureBoxItem.description}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
                ))}

            </Grid> 

        </Container>
    </Box>


    </>
  )
}

export default DrrzFeatures

