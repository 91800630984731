import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography, Card, CardMedia } from "@mui/material";

import PlaceIcon from '@mui/icons-material/Place';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Register from '../Register/Register';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import GreenBanner from '../BaseUrl/GreenBanner';


import moment from "moment";

const styles = {
    packageBox: {
        boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px',
        '@media (max-width: 900px)': { marginTop: '40px', },
    },
    scheduleBox: {
        border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px', display: 'flex',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            gap: '20px',
        },
    },
}

const programsData = [
    {
        prmTop1: "Starting",
        prmTop2: "$360",
        prmTittle: "DUI Risk Reduction class (Location)",
        prmTittleSub: "20 hours",
        prmDescription: "<b>$100</b> (for Assessment Questionnaire) <b>+ $260</b> (for class and book)",
        prmDescription1: "(Mandated by the Georgia Department of Driver Services (DDS).",
    },
];










const DrrzPackages = () => {


    const [roadTesting, setRoadTesting] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllCourse`, {
                type: ''
            })
            if (response.data.message === "success") {
                console.log('response', response?.data?.response)

                const course5 = response?.data?.response[1]
                setRoadTesting(course5)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
    }, [])
    console.log('roadTesting', roadTesting)



    const [registerStatus, setRegisterStatus] = useState(false)

    const [finalData, setFinalData] = useState({})

    const fetchcheckPackageisExist = async (packageid) => {
        try {
            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageid
            })
            if (response.data.message === 'success') {
                console.log('response', response?.data?.response[0])
                setFinalData(response?.data?.response)
                setRegisterStatus(true)
            } else {
                setFinalData({})
            }
        } catch (error) {
            console.log(error)
        }
    }



    console.log('finalData', finalData)






    const getDaysWeekdays = (id) => {
        switch (id) {
          case "1":
            return "Monday";
          case "2":
            return "Tuesday";
            case "3":
            return "Wednesday ";
            case "4":
            return "Thursday ";
            case "5":
            return "Friday ";

            default:
                return null;
        }
      };

    const getDaysWeekend = (id) => {
        switch (id) {
            case "6":
                return "Saturday";
          case "0":
            return "Sunday";

            default:
                return null;
        
        }
    };

    // Ensure roadTesting and scheduleDetails are defined
    const weekdaysSchedule = roadTesting?.scheduleDetails?.filter(item => ["1", "2", "3", "4", "5"].includes(item.dayname)) || [];
    const weekendSchedule = roadTesting?.scheduleDetails?.filter(item => ["0", "6"].includes(item.dayname)) || [];


    return (
        <>
            <Box sx={{ py: "50px", }}>
                <Container>
                    <Typography variant="h4" sx={{ pt: 2 }}> Package and Curriculum </Typography>


                    <Grid container spacing={2} mt={4}>

                        <Grid item md={7} >

                            {/* first row */}
                            <Grid sx={{ border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px', mb: 2 }}>
                                

                                <Grid sx={{ display: 'flex', gap: '20px', flexDirection: 'column', }}>
                                    <Grid sx={{ background: '#EFF7FF', padding: '20px' }}>
                                        <Typography mb="10px" variant='h2'>Quick and Easy Needs Assessment</Typography>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>Georgia Department of Driver Services (DDS) requires that you complete this questionnaire prior to the start of your class.</Typography>
                                        <br/>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>A 20-30 minutes test which can be completed from the comfort of your home.</Typography>
                                        <br/>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>Link to the questionnaire will be sent to the email provided during purchase.</Typography>
                                    </Grid>
                                    <Grid sx={{ background: '#F2FFF9', padding: '20px' }}>
                                        <Typography mb="10px" variant='h2'>Choose Your 20-Hour DUI/Risk Reduction Class</Typography>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>Once you've completed the assessment, it's time for the 20-hour duration DUI/ Risk Reduction course.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>



                            {/* second row */}
                            <Grid sx={styles.scheduleBox}>

                                <Grid sx={{ width: '100%', }}>
                                    <Typography component="f18w600">Class Schedule</Typography>

                                    <Grid sx={{ margin: "10px 0px 0px",display: "flex",gap:"20px",flexDirection:"row",justifyContent:"space-between",}}>

                                        <Grid sx={{ background: "#fff", p: "20px 30px ",}}>
                                            <Typography component="f16w600">Weekdays Schedule</Typography>
                                            {weekdaysSchedule?.map((item, index) => (
                                            <Typography key={index} variant="h6" sx={{ fontWeight: "400 !important", display: "flex", alignItems: "baseline",marginTop:'10px',}}>
                                                <AccessTimeFilledIcon sx={{ color: "#5B5B5B", fontSize: "16px" }}/>
                                                &nbsp;
                                                <span>
                                                <b style={{color:'var(--primary) !important',fontSize:'16px',}}> {getDaysWeekdays(item.dayname)} </b>
                                                <br />
                                                <b style={{fontWeight:'500',}}>( { moment(item?.starttime).format('hh:mm A')} - { moment(item?.endtime).format('hh:mm A')} )</b>
                                                </span>
                                            </Typography>
                                            ))}
                                        </Grid>

                                        <Grid sx={{ background: "#fff", p: "20px 30px" }}>
                                            <Typography component="f16w600">Weekend Schedule</Typography>
                                            {weekendSchedule.map((item, index) => (
                                                <Typography key={index} variant="h6" sx={{ fontWeight: "400 !important", display: "flex", alignItems: "baseline",marginTop:'10px', }}>
                                                    <AccessTimeFilledIcon sx={{ color: "#5B5B5B", fontSize: "16px" }} />
                                                    &nbsp;
                                                    <span>
                                                        <b style={{ color: 'var(--primary) !important', fontSize: '16px' }}> {getDaysWeekend(item.dayname)} </b>
                                                        <br />
                                                        <b style={{ fontWeight: '500' }}>( {moment(item?.starttime).format('hh:mm A')} - {moment(item?.endtime).format('hh:mm A')} )</b>
                                                    </span>
                                                </Typography>
                                            ))}
                                        </Grid>

                                        
                                    </Grid>

                                </Grid>



                            </Grid>





                        </Grid>

                        <Grid item md={5} id='Course'>
                            {/* {programsData.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} sm={11} md={11} sx={styles.packageBox}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                {program.prmTop1}
                                            </Typography>
                                            <Typography variant="h3" sx={{ color: "#fff !important" }}>
                                                {program.prmTop2}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_con">

                                            <Grid className="prgrm_txt">
                                                <Grid className="" style={{ padding: "100px 20px 0px" }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            fontWeight: "600 !important",
                                                        }}
                                                    >
                                                        {program.prmTittle}
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            margin: "0px 0px 16px",
                                                            fontWeight: "600 !important",
                                                            color: "var(--primary) !important"
                                                        }}
                                                    >
                                                        {program.prmTittleSub}
                                                    </Typography>
                                                    <Typography component="f14" dangerouslySetInnerHTML={{ __html: program.prmDescription.replace(/<br\s*\/?>/mg, "<br/>", "'", "<b>") }}></Typography>
                                                    <Typography component="f14" dangerouslySetInnerHTML={{ __html: program.prmDescription1.replace(/<br\s*\/?>/mg, "<br/>", "'", "<b>") }}></Typography>
                                                </Grid>

                                                <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                    <a className="btns btn_1 hvr-bounce-to-right">
                                                        GET NOW
                                                    </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))} */}

                            {roadTesting?.courseDetails?.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                Starting
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                sx={{ color: "#fff !important" }}
                                            >
                                                ${program.packageamount}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_img" style={{ padding: "60px 20px 0px" }} >
                                            <CardMedia
                                                className=""
                                                component="img"
                                                height="200px"
                                                width="100%"
                                                alt="program Image"
                                                src={roadTesting.productimage}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                        </Grid>

                                        {/* <Grid className="prgrm_con"> */}

                                        <Grid className="prgrm_txt">
                                            <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        fontSize: "18px !important",
                                                        margin: "0px 0px 16px",
                                                    }}
                                                >
                                                    {program.packagename}
                                                </Typography>

                                                <Typography
                                                    variant="h4"
                                                    sx={{
                                                        fontSize: "18px !important",
                                                        margin: "0px 0px 16px",
                                                        fontWeight: "600 !important",
                                                        color: "var(--primary) !important"
                                                    }}
                                                >
                                                    {program.duration} Hrs
                                                </Typography>


                                                <Typography sx={{ fontSize: "12px !important" }}>
                                                    {roadTesting.description}
                                                </Typography>
                                            </Grid>

                                            <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                <a className="btns btn_1 hvr-bounce-to-right">
                                                    GET NOW
                                                </a>
                                            </Grid>

                                        </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </Grid>
                            ))}










                        </Grid>

                        <Register
                            openStatus={registerStatus}
                            setRegisterStatus={setRegisterStatus}
                            finalData={finalData}
                            classmode = {'1'}
                        />

                    </Grid>

                    {/* <Grid container spacing={2} sx={{ marginTop: '30px', '@media (max-width: 900px)': { marginTop: '0px', } }}>

                        <Grid item md={6} sx={{ '@media (max-width: 900px)': { width: '100% !important' } }}>
                            <Grid sx={styles.scheduleBox}>
                                <Grid sx={{ width: '100%', }}>
                                    <Typography marginBottom="20px" component="f18w600">Weekday Class Schedule</Typography>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', }}>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Tuesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Wednesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Thursday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ width: '100%', }}>
                                    <Typography mb="10px" component="f18w600">Weekend Class Schedule</Typography>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', width: '100%', }}>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Friday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Saturday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Sunday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid> */}

                    <GreenBanner/>

                </Container>
            </Box>
        </>
    )
}

export default DrrzPackages
