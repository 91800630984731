import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import drrlEsyStpBg from "../../assets/images/DefensiveDrivingLocation/drrlEsyStpBg.png"
import easyStepsRoad from "../../assets/images/DefensiveDrivingLocation/EasyStepsRoad.png"



const styles = {
    easyStepRoadimg: {
        backgroundSize:'100% 100%',
        objectFit:'fill',
        width:'140px',
        height:'150px',
    },
    EsyStpBox: {
        display:'grid !important',
        gridTemplateColumns:'40% 20% 40%',
        alignItems:'center',
    }
};


const DrrzEasySteps = () => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        beforeChange: function(currentSlide, nextSlide) {
          console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function(currentSlide) {
          console.log("after change", currentSlide);
        },
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                vertical: false,
                verticalSwiping: false,
              },
            },
        ],
      };


  return (
    <>

        <Box className="EasyStepBg" sx={{ pt:"50px", pb:'80px', background: `url(${drrlEsyStpBg}) no-repeat top center`,}}>
        <Container>

            <Grid>
                <Typography variant="h4" sx={{ pb:'80px',  textAlign:'center', }}> Easy Steps to complete the DUI / Risk Reduction </Typography>
            </Grid>

            <Grid>
            <div className="slider-container DrrlSlider">
                <Slider {...settings}>

                    <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                        <Grid className='esystptxt esystptxtlft esystptxtlft1'>
                            <Grid>
                                <Typography variant='h2' sx={{mb:'16px',}}>Gear Up</Typography>
                                <Typography component='f14'>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</Typography>
                            </Grid>
                        </Grid>
                        <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                            <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                        </Grid>
                        <Grid className='esystptxt esystptxtrht'>
                        </Grid>
                    </Box>

                    <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                        <Grid className='esystptxt esystptxtlft'>
                        </Grid>
                        <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                            <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                        </Grid>
                        <Grid className='esystptxt esystptxtrht esystptxtrht2'>
                            <Grid>
                                <Typography variant='h2' sx={{mb:'16px',}}>Complete GAARP  Registration</Typography>
                                <Typography component='f14'>Needs Assessment Questionnaire  (Demo Video Link): http: GAARP REG LINK: Http:</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                        <Grid className='esystptxt esystptxtlft esystptxtlft3'>
                            <Grid>
                                <Typography variant='h2' sx={{mb:'16px',}}>Complete 20-hour  DUI/Risk Reduction course at location</Typography>
                                <Typography component='f14'>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</Typography>
                            </Grid>
                        </Grid>
                        <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                            <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                        </Grid>
                        <Grid className='esystptxt esystptxtrht'>
                        </Grid>
                    </Box>

                    <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                        <Grid className='esystptxt esystptxtlft'>
                        </Grid>
                        <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                            <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                        </Grid>
                        <Grid className='esystptxt esystptxtrht esystptxtrht4'>
                            <Grid>
                                <Typography variant='h2' sx={{mb:'16px',}}>Virtual /In-Person</Typography>
                                <Typography component='f14'>Needs Assessment Questionnaire  (Demo Video Link): http: GAARP REG LINK: Http:</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                        <Grid className='esystptxt esystptxtlft esystptxtlft5'>
                            <Grid>
                                <Typography variant='h2' sx={{mb:'16px',}}>Class & Assessment</Typography>
                                <Typography component='f14'>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</Typography>
                            </Grid>
                        </Grid>
                        <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                            <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                        </Grid>
                        <Grid className='esystptxt esystptxtrht'>
                        </Grid>
                    </Box>

                    <Box className="EsyStpBox" sx={styles.EsyStpBox}>
                        <Grid className='esystptxt esystptxtlft'>
                        </Grid>
                        <Grid className='esystpRoad' sx={{display:'flex', justifyContent:'center',}}>
                            <CardMedia className="" component="img" alt="program Image" src={easyStepsRoad} sx={styles.easyStepRoadimg}/>
                        </Grid>
                        <Grid className='esystptxt esystptxtrht esystptxtrht6'>
                            <Grid>
                                <Typography variant='h2' sx={{mb:'16px',}}>Certification</Typography>
                                <Typography component='f14'>Needs Assessment Questionnaire  (Demo Video Link): http: GAARP REG LINK: Http:</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                </Slider>
            </div>

            </Grid>

            </Container>
        </Box>
      
    </>
  )
}

export default DrrzEasySteps
